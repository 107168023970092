<template>
  <div class="main" :class="{ lightMain: lightMode }">
    <div class="container">
      <div class="background-img">
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 1064 1023"
          style="enable-background: new 0 0 800 801.1"
          xml:space="preserve"
        >
          <g>
            <path
              class="path"
              :class="{ lightPath: lightMode }"
              d="M918.5,511.3l137.4-252.9H865.8L832,320.7l-17.2-64.1L747,5.2H592.1L7.8,1017.2h176.9l461-798.3
	l55,205.8l23.2,86.6h-66.8L519.8,764.3h-71.2l-137.4,252.9h190.1l137.4-252.9h71.2l54.8-100.9l95.1,353.8h158.4L883.1,511.3
	L918.5,511.3z"
            />
          </g>
        </svg>
      </div>
      <div class="wallet-address" :class="{ lightWalletAddress: lightMode }">
        <div v-if="data.showWallet">
          <div  class="container-wallet">
            <div class="algo-img"><img src="@/assets/icons/Algo.svg" /></div>
            <div class="title" :class="{ lightTitle: lightMode }">
              Your Wallet Address
            </div>
            <i class="material-icons" :class="{ lightMaterialIcons: lightMode }" v-on:click="toggle">
              link
            </i>
          </div>
          <div class="address" :class="{ lightAddress: lightMode }">
            {{ data.walletAddress }}
          </div>
          <div class="balance_header" :class="{ lightAddress: lightMode }">
            Remaining Balance For Compounding
          </div>
          <div class="balance_block algo-value" :class="{ lightColumn: lightMode }">
            <div>
              <img src="@/assets/icons/Algo.svg" />
            </div>
            <div class="reward">
              {{ data.remainingBalance }}
            </div>
          </div>
        </div>
        <div v-else>
          <div  class="container-wallet">
            <div class="algo-img"><img src="@/assets/icons/Algo.svg" /></div>
            <div class="title" :class="{ lightTitle: lightMode }">
              Unlink Address
            </div>
            <i class="material-icons" :class="{ lightMaterialIcons: lightMode }" v-on:click="toggle">
              close
            </i>
          </div>
          <div class="message" :class="{ lightAddress: lightMode }">
            To unlink your address from the service, send a 0 Algo transaction with an entry in the note field of 'stop' to the following address. Please allow 24hours for processing.
          </div>
          <div class="address-section" :class="{ lightAddress: lightMode }">
            <div class="address">{{ copyTextSpaced }}</div>
            <div class="copy" :class="{ lightCopy: lightMode }" @click="doCopy">
              Copy address
            </div>
            <transition name="fade">
              <div class="copied" v-if="showCopied">Copied!</div>
            </transition>
          </div>
        </div>
      </div>
      <div class="connector" :class="{ lightConnector: lightMode }">
        <svg  x="0px" y="0px" viewBox="0 0 168.6 21.9" >
	        <line  class="path" x1="14.1" y1="11" x2="154.1" y2="11"/>
        </svg>
      </div>
      <div class="statistics">
        <h1 :class="{ lightH1: lightMode }">Your Statistics</h1>
        <div class="row">
          <div class="column" :class="{ lightColumn: lightMode }">
            <div class="cell-title">Current Balance</div>
            <div class="cell-value" :class="{ lightCellValue: lightMode }">
              <div class="container-algo">
                <div class="algo-img">
                  <img src="@/assets/icons/Algo.svg" />
                </div>
                <div class="amount">{{ data.currentBalance }}</div>
              </div>
            </div>
          </div>
          <div class="column" :class="{ lightColumn: lightMode }">
            <div class="cell-title">Current lifetime staking rewards</div>
            <div class="cell-value" :class="{ lightCellValue: lightMode }">
              <div class="container-algo">
                <div class="algo-img">
                  <img src="@/assets/icons/Algo.svg" />
                </div>
                <div class="amount">{{ data.lifetime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column" :class="{ lightColumn: lightMode }">
            <div class="cell-title">Best compounding schedule</div>
            <div class="cell-value" :class="{ lightCellValue: lightMode }">
              {{ data.optimalMinutes }}
            </div>
          </div>
          <div class="column" :class="{ lightColumn: lightMode }">
            <div class="cell-title">Next 0 Algo Transfer</div>
            <div class="cell-value" :class="{ lightCellValue: lightMode }">
              {{ data.nextTransfer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, computed, ref } from "vue";
import { useRoute } from "vue-router";
import { secondsToHms, dateToStr } from "@/helpers";
import axios from "axios";
import store from "@/store";
import { copyText } from "vue3-clipboard";

export default {
  setup() {
    let showCopied = ref(false);
    const lightMode = computed(() => store.getters.lightMode);
    const fmt = (str) => {
      return [str.slice(0, 33), " ", str.slice(33)].join("");
    };

    const route = useRoute();
    const data = reactive({
      walletAddress: fmt(route.params.wallet),
      currentBalance: "###",
      optimalMinutes: 0,
      nextTransfer: "###",
      lifetime: "###",
      remainingBalance: '####',
      showWallet: true,
    });

    const lifeTimeRequest = async () => {
      const response = await axios.get(
        "https://algoexplorerapi.io/v2/accounts/" + route.params.wallet
      );
      return await response.data;
    };

    const doRequest = async () => {
      const response = await axios.get(
        "https://algooptimizer.com/service/wallet/" + route.params.wallet
      );
      return await response.data;
    };

    const getter = async () => {
      const response = await doRequest();

      data.remainingBalance = response.remaining_balance
      data.currentBalance = response.current_remote_balance;
      data.optimalMinutes = secondsToHms(response.optimal_minutes);
      data.nextTransfer = dateToStr(response.next_transfer);
      store.commit("updateTransactions", response.transfers);
    };

    const lifetimeGetter = async () => {
      const response = await lifeTimeRequest();

      data.lifetime = response.rewards / 1000000.0;
    };

    const toggle = () => {
      data.showWallet = !data.showWallet;
    }

    let token;
    let token2;
    onMounted(() => {
      getter();
      lifetimeGetter();
      token = window.setInterval(getter, 5000);
      token2 = window.setInterval(lifetimeGetter, 20000);
    });

    onUnmounted(() => {
      window.clearInterval(token);
      window.clearInterval(token2);
    });

    const copyString =
      "5AFBATSGEMY2ATGVJ465RNW5VKECJYAJACOXXT22O6S2KXB6KN3Q6N6PUM";

    const doCopy = () => {
      copyText(copyString, undefined, (error, event) => {
        if (error) {
          console.log(error);
        } else {
          showCopied.value = true
          setTimeout(() => {
            showCopied.value = false;
          }, 1000);
          console.log(event);
        }
      });
    };

    let copyTextSpaced = copyString.substring(0, 30) + " " + copyString.substring(30, copyString.length);

    return { lightMode, data, toggle, copyTextSpaced, showCopied, doCopy };
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  display: flex;
  position: relative;
  background-color: $background-standard;
  color: $body-text;
  overflow: hidden;
  @media (min-width: $desktop) {
    padding-left: 50px;
  }
  @media (min-width: $large-desktop) {
    flex-direction: row;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.lightMain {
  background-color: $background-standard-light;
  color: $body-text-light;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-between;
  max-width: $large-desktop;
  margin: auto;
  @media (min-width: $large-desktop) {
    flex-direction: row;
  }
}

.title {
  opacity: 0.68;
  margin-left: -20px;
}

.lightTitle {
  color: $white;
}

.wallet-address {
  width: 413px;
  background-color: $black;
  text-align: center;
  padding-top: 21px;
  margin-top: 50px;
  margin-bottom: 100px;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
  padding-right: 15px;
  padding-left: 5px;
  @media (min-width: $large-desktop) {
    margin-top: 111px;
    margin-bottom: 154px;
    margin-right: 0;
  }
}

.lightWalletAddress {
  background-color: $body-text-accented-light;
  box-shadow: 0px 3px 6px $box-shadow-light;
}

.container-wallet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.container-algo {
  display: flex;
  align-items: center;
  margin-left: -20px;
}

.algo-img {
  position: relative;
  top: -2px;
  margin-bottom: -40px;
  margin-top: -30px;
  img {
    width: 40px;
    margin-left: 5px;
    margin-right: 10px;
    @media (min-width: 1455px) {
      width: 70px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.address {
  margin-top: 58px;
  font-size: 0.875rem;
}

.message {
  margin-top: 58px;
  margin-bottom: 58px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.875rem;
}

.balance_header {
  margin-top: 40px;
  margin-bottom: 5px;
  font-size: 0.875rem;
}

.balance_block {
  display: inline-block;
  align-items: center;
  height: 26px;
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 10px;
  margin-bottom: 20px;
  background: $background-statistics 0% 0% no-repeat;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
}

.algo-value {
  display: inline-flex;
  align-items: center;
  line-height: 1.5;
  img {
    width: 30px;
    margin-right: -5px;
  }
}

.reward {
  padding-bottom: 5px;
}


.lightAddress {
  color: $white;
}

.material-icons {
  font-size: 2rem;
  color: $highlight;
  cursor:pointer;
}

.lightMaterialIcons {
  color: $highlight-light;
}

.statistics {
  position: relative;
  z-index: 1;
  margin-top: -90px;
  margin-bottom: 60px;
  @media (min-width: $large-desktop) {
    margin-bottom: 0;
  }
}

.row {
  display: flex;
  flex-direction: column;
  @media (min-width: $large-desktop) {
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;
  background: $background-statistics 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
  width: 300px;
  height: 82px;
  margin-right: 16px;
  margin-bottom: 14px;
  padding: 9px 0 11px 15px;
  @media (min-width: $desktop) {
    width: 340px;
    margin-left: 20px;
  }
  @media (min-width: 1255px) {
    margin-left: 0;
    width: 250px;
  }
  @media (min-width: 1455px) {
    width: 340px;
  }
}

.lightColumn {
  background: $background-statistics-light 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow-light;
}

.cell-title {
  font-size: 0.875rem;
}

.cell-value {
  color: $highlight;
  font-size: 1.35rem;
  @media (min-width: 1455px) {
    font-size: 1.8125rem;
  }
  .container {
    justify-content: flex-start;
    margin-left: -20px;
  }
}

@-moz-document url-prefix() {
  .cell-value {
    line-height: 2;
  }
}

.lightCellValue {
  color: $highlight-light;
}

.amount {
  margin-left: -13px;
}

.superscript {
  font-size: 1.3rem;
  margin-left: 7px;
}

.connector {
  display: none;
  color: $highlight;
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
  width: 200px;
  @media (min-width: $large-desktop) {
    display: block;
  }
}

.lightConnector {
  color: $highlight-light;
}

.background-img {
  position: absolute;
  top: -110px;
  right: -70px;
  z-index: 0;
  height: 100%;
  width: 700px;
  display: none;
  @media (min-width: $large-desktop) {
    display: block;
  }
}

h1 {
  font-size: 1.875rem;
  font-weight: 600;
  color: $body-text-accented;
  text-align: center;
  @media (min-width: $large-desktop) {
    text-align: left;
  }
}

.lightH1 {
  color: $body-text-accented-light;
}

.path {
  fill: none;
  stroke: $algo-img-background;
  stroke-miterlimit: 10;
}

.lightPath {
  stroke: $algo-img-background-light;
}

@keyframes dash {
  to {
    stroke-dashoffset: -1000;
  }
}

.path {
  animation: dash 40s linear infinite;
  stroke-dasharray: 4;
  fill:none;
  stroke: $selected-link;
  stroke-miterlimit:10;
}

.address-section {
  display: inline-block;
  padding: 10px 10px 5px 10px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  .title {
    opacity: 0.68;
    margin-bottom: 9px;
  }
  .address {
    margin-top: 0px;
    text-align: center;
    width: 290px;
    margin-bottom: 5px;
  }
  .copy {
    color: $highlight;
    cursor: pointer;
  }
  .lightCopy {
    color: $highlight-light;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
