<template>
  <main>
    <the-header></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </main>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import { computed, watch } from "vue";
import store from "@/store";

export default {
  name: "App",
  components: { TheHeader, TheFooter },
  setup() {
    const lightMode = computed(() => store.getters.lightMode);
    watch(lightMode, (val) => {
      if (val) {
        document.querySelector("body").classList.add("lightBody");
      } else {
        document.querySelector("body").classList.remove("lightBody");
      }
    });
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html {
  font-family: "Overpass", sans-serif;
}

body {
  margin: 0 auto !important;
  float: none !important;
  background-color: $black;
}

.lightBody {
  background-color: $body-text-light;
}

main {
  min-height: 100%;
  display: grid;
  grid-template-rows: 56px auto auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
}

a,
a:link,
a:visited {
  text-decoration: none !important;
  color: inherit;
}
</style>
