<template>
  <div class="main" :class="{ lightMain: lightMode }">
    <div class="container">
      <h2 class="header">Terms of use</h2>
      <div class="text">
        <p>
          By sending your 1 Algo to the wallet 5AFBATSGEMY2ATGVJ465RNW5VKECJYAJACOXXT22O6S2KXB6KN3Q6N6PUM, you are opting into 0 Algo transfers that are sent at a frequency determined by the service to optimally compound your staking rewards.
        </p>
        <p>
          This frequency is based on the current APY and your wallet balance as seen on the blockchain.  Balance update and compounding frequency adjustments are made anytime a transaction involving your wallet are seen on the blockchain. 
        </p>
        <p>
Pausing the service can be achieved by sending a 0 Algo transaction to 5AFBATSGEMY2ATGVJ465RNW5VKECJYAJACOXXT22O6S2KXB6KN3Q6N6PUM with an entry in the note field of "pause".  
Resuming the service can be achieved by sending a 0 Algo transaction to 5AFBATSGEMY2ATGVJ465RNW5VKECJYAJACOXXT22O6S2KXB6KN3Q6N6PUM with an entry in the note field of "resume".
        </p>
        <p> 
          Any and all data stored locally and needed to run the service is obtained via the publicly available data found on the Algorand blockchain and thus is automatically available to Algooptimizer.
        </p>
        <p>
          THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "@/store";
export default {
  setup() {
    const lightMode = computed(() => store.getters.lightMode);
    return { lightMode };
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  background-color: $background-statistics;
  color: $body-text;
}

.container {
  display: flex;
  flex-direction: column;
  width: $mobile;
  align-items: center;
  margin: auto;
  @media (min-width: $desktop) {
    width: $desktop;
  }
  @media (min-width: $large-desktop) {
    width: $large-desktop;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.lightMain {
  background-color: $background-statistics-light;
  color: $body-text-light;
}

.text p {
  width: 300px;
  font-size: 0.875rem;
  padding-right: 10px;
  @media (min-width: $desktop) {
    width: 500px;
    padding-right: 0;
  }
  @media (min-width: $large-desktop) {
    font-size: 1rem;
    width: 726px;
  }
}

h2 {
  font-size: 1.625rem;
  font-weight: 600;
  @media (min-width: $large-desktop) {
    font-size: 1.875rem;
  }
}

h2.header {
  margin-top: 64px;
}

hr {
  width: 200px;
  border-color: $body-text-accented;
}

.lightHr {
  border-color: $body-text-accented-light;
  opacity: 0.1;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 5px;
  row-gap: 8px;
  margin-bottom: 120px;
  @media (min-width: $large-desktop) {
    grid-template-columns: repeat(4, auto);
    column-gap: 17px;
    row-gap: 20px;
  }
}

.cell {
  width: 149px;
  height: 118px;
  text-align: center;
  padding-top: 10px;
  background: $background-standard 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
  opacity: 0.56;
  @media (min-width: $large-desktop) {
    width: 179px;
  }
}

.lightCell {
  background: $background-standard-light 0% 0% no-repeat padding-box;
}

.name {
  font-weight: 800;
  margin-bottom: 10px;
}

.role {
  color: $body-text-accented;
  margin-bottom: 13px;
}

.lightRole {
  color: $body-text-accented-light;
}

.tip {
  font-weight: 900;
  color: $highlight;
  cursor: pointer;
}

.lightTip {
  color: $highlight-light;
}
</style>