<template>
  <div class="main" :class="{ lightMain: lightMode }">
    <div class="container">
      <div class="left">
        <h1 :class="{ lightH1: lightMode }">Welcome to Algo Optimizer</h1>
        <p>
          To optimize staking, it is important to compound your balance. If you
          have a Buy and Hold strategy, you need to periodically update the
          balance on the blockchain so that your staking reward is based on your
          current balance.
        </p>
        <p class="bottom-para">
          To facilitate these updates, a 0 ALGO transaction must be sent to your
          wallet, which will add your staking rewards to your wallet and start
          compounding with that new, higher balance!
        </p>
      </div>
      <div class="right">
        <div class="background-img">
          <svg
            x="0px"
            y="0px"
            viewBox="0 0 1064 1023"
            style="enable-background: new 0 0 800 801.1"
            xml:space="preserve"
          >
            <g>
              <path
                class="path"
                :class="{ lightPath: lightMode }"
                d="M918.5,511.3l137.4-252.9H865.8L832,320.7l-17.2-64.1L747,5.2H592.1L7.8,1017.2h176.9l461-798.3
	l55,205.8l23.2,86.6h-66.8L519.8,764.3h-71.2l-137.4,252.9h190.1l137.4-252.9h71.2l54.8-100.9l95.1,353.8h158.4L883.1,511.3
	L918.5,511.3z"
              />
            </g>
          </svg>
        </div>
        <h2 :class="{ lightH2: lightMode }">Live statistics</h2>
        <div class="grid">
          <div class="rows row1">
            <div class="groups group1" :class="{ lightGroups: lightMode }">
              <div class="column">
                <div>Users</div>
                <div class="value" :class="{ lightValue: lightMode }">
                  {{ stats.data.totalUsers }}
                </div>
              </div>
              <div
                class="vertical-rule"
                :class="{ lightVerticalRule: lightMode }"
              ></div>
              <div class="column">
                <div class="bump-up">
                  New <sup class="superscript">(24h)</sup>
                </div>
                <div class="value" :class="{ lightValue: lightMode }">
                  {{ stats.data.lastUsers }}
                </div>
              </div>
            </div>
            <div class="groups group2" :class="{ lightGroups: lightMode }">
              <div class="column">
                <div class="last-trans">
                  Last transactions <sup class="superscript">(24h)</sup>
                </div>
                <div class="value" :class="{ lightValue: lightMode }">
                  {{ stats.data.lastTransactions }}
                </div>
              </div>
              <div
                class="vertical-rule"
                :class="{ lightVerticalRule: lightMode }"
              ></div>
              <div class="column">
                <div class="staking-rewards">Generated staking rewards</div>
                <div class="value" :class="{ lightValue: lightMode }">
                  <div class="algo-container">
                    <div class="algo">
                      <img src="@/assets/icons/Algo.svg" />
                    </div>
                    <div class="algo-value">{{ stats.data.lastRewards }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rows row2">
            <div class="groups group2" :class="{ lightGroups: lightMode }">
              <div class="column">
                <div class="column-header">Average compounding time</div>
                <div class="value">
                  {{ stats.secondsToHms(stats.data.averageCompoundingTime) }}
                </div>
              </div>
            </div>
            <div class="groups group3" :class="{ lightGroups: lightMode }">
              <div class="column">
                <div class="staking-rewards">Total staking rewards</div>
                <div class="value">
                  <div class="algo-container">
                    <div class="algo algo-total-staking">
                      <img src="@/assets/icons/Algo.svg" />
                    </div>
                    <div class="algo-value total-staking-rewards">
                      {{ stats.data.totalStakingRewards }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStats } from "@/helpers";
import { computed } from "vue";
import store from "@/store";

export default {
  setup() {
    const lightMode = computed(() => store.getters.lightMode);
    const stats = getStats();

    return { lightMode, stats };
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  background-color: $background-standard;
  color: $body-text-accented;
  overflow: hidden;
}

.lightMain {
  background-color: $background-standard-light;
  color: $body-text-accented-light;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: $mobile;
  margin: auto;
  max-width: $large-desktop;
  @media (min-width: $desktop) {
    width: $desktop;
  }
  @media (min-width: $large-desktop) {
    flex-direction: row;
    width: $large-desktop;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.left {
  text-align: center;
  @media (min-width: $large-desktop) {
    width: 445px;
    margin-right: 148px;
    text-align: left;
  }
}

.right {
  position: relative;
  margin-left: 10px;
  @media (min-width: $large-desktop) {
    margin-left: 0;
    margin-right: 50px;
  }
}

.bottom-para {
  @media (min-width: $large-desktop) {
    margin-bottom: 82px;
  }
}

.grid {
  display: flex;
  flex-direction: column;
}

.rows {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  @media (min-width: $desktop) {
    flex-direction: row;
    margin-bottom: 18px;
  }
}

.row1 {
  @media (min-width: $large-desktop) {
    height: 86px;
  }
}

.row2 {
  margin-bottom: 100px;
  @media (min-width: $large-desktop) {
    margin-left: 0;
  }
}

.groups {
  position: relative;
  white-space: nowrap;
  z-index: 1;
  height: 100%;
  display: flex;
  background: $background-statistics 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
  margin-right: 16px;
  padding: 10px 10px 10px 15px;
  width: 100%;
  line-height: 1.6;
}

.lightGroups {
  background: $background-statistics-light 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow-light;
}

.value {
  font-weight: bold;
  font-size: 1.1875rem;
  color: $highlight;
  @media (min-width: $large-desktop) {
    font-size: 1.8125rem;
  }
}

.lightValue {
  color: $highlight-light !important;
}

.column {
  display: flex;
  flex-direction: column;
  font-size: 0.625rem;
  margin: auto;
  @media (min-width: $desktop) {
    margin-right: 15px;
    margin-left: 0;
  }
  @media (min-width: $large-desktop) {
    font-size: 0.875rem;
  }
}

.group1 {
  width: 100%;
  margin-bottom: 10px;
  @media (min-width: $desktop) {
    width: 150px;
  }
  @media (min-width: $large-desktop) {
    width: 205px;
  }
}

.group3 {
  width: 100%;
  margin-top: 10px;
  @media (min-width: $desktop) {
    width: 208px;
    margin-top: 0;
  }
  @media (min-width: $large-desktop) {
    width: 278px;
  }
}

.superscript {
  font-size: 0.5rem;
}

.vertical-rule {
  border-left: 1px solid;
  height: 50px;
  margin-right: 15px;
  color: $vertical-rule;
  @media (min-width: $large-desktop) {
    height: 70px;
  }
}

.lightVerticalRule {
  color: $vertical-rule-light;
}

.background-img {
  display: none;
  position: absolute;
  top: -80px;
  right: 0;
  z-index: 0;
  height: 100%;
  width: 595px;
  @media (min-width: $large-desktop) {
    display: block;
  }
}

.algo {
  margin-left: -10px;
  margin-right: 25px;
  margin-bottom: 5px;
  @media (min-width: $large-desktop) {
    margin-bottom: 0;
    margin-left: -20px;
  }
  img {
    width: 35px;
    @media (min-width: $large-desktop) {
      width: 55px;
    }
  }
}

.algo-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: -18px;
}

.algo-value {
  margin-top: -12px;
  margin-left: -30px;
}

.staking-rewards {
  @media (min-width: $large-desktop) {
    margin-bottom: -5px;
  }
}

.bump-up {
  margin-top: -3px;
  @media (min-width: $large-desktop) {
    margin-top: 0;
  }
}

h1 {
  margin-top: 50px;
  margin-bottom: 34px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  font-size: 1.625rem;
  color: $body-text;
  font-weight: 600;
  line-height: 1.17;
  @media (min-width: $large-desktop) {
    margin-top: 97px;
    width: 500px;
    font-size: 3.125rem;
  }
}

.lightH1 {
  color: $body-text-light;
}

h2 {
  font-weight: 600;
  font-size: 1.375rem;
  color: $body-text-accented;
  text-align: center;
  @media (min-width: $large-desktop) {
    font-size: 1.875rem;
    text-align: left;
  }
}

.lightH2 {
  color: $body-text-accented-light;
}

p {
  width: 300px;
  margin-top: 0;
  font-size: 0.875rem;
  @media (min-width: $desktop) {
    width: 429px;
  }
  @media (min-width: $large-desktop) {
    font-size: 1rem;
  }
}

.path {
  fill: none;
  stroke: $algo-img-background;
  stroke-miterlimit: 10;
}

.lightPath {
  stroke: $algo-img-background-light;
}

.last-trans {
  margin-right: 17px;
  @media (min-width: $desktop) {
    margin-right: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}

.path {
  animation: dash 30s linear infinite;
  stroke-dasharray: 4;
}
</style>