<template>
  <div class="main" :class="{ lightMain: lightMode }">
    <h2 class="heading">Getting Started</h2>
    <div class="text">
      <p>
        To get started using the service there is a 1 step process with an
        optional second step!
      </p>
      <p class="bold line2">
        Attention Coinbase users! This service is probably not going to help
        things much.
      </p>
      <p class="bold line3">
        The
        <a
          href="https://algorandwallet.com/"
          target="_blank"
          class="link"
          :class="{ lightLink: lightMode }"
        >
          official wallet
        </a>
        is and would be a good choice to use in conjunction with this service.
      </p>
    </div>
    <hr />
    <h2>Step 1</h2>
    <div class="text">
      <p>
        Send 1.00 ALGO to the address below and momentarily you will see a
        deposit of 0.00 ALGO confirming your participation.
      </p>
    </div>
    <div class="qrcode" :class="{ lightQrcode: lightMode }">
      <img src="@/assets/images/QR.svg" />
    </div>
    <div class="address-section" :class="{ lightAddressSection: lightMode }">
      <div class="title">Algo Optimizer Address</div>
      <div class="address">{{ copyTextSpaced }}</div>
      <div class="copy" :class="{ lightCopy: lightMode }" @click="doCopy">
        Copy address
      </div>
      <transition name="fade">
        <div class="copied" v-if="showCopied">Copied!</div>
      </transition>
    </div>
    <hr :class="{ lightHr: lightMode }" />
    <h2>Step 2 <sup class="superscript">(optional)</sup></h2>
    <div class="text">
      <div class="line1">
        Enter your wallet address below and once your deposit appears you will
        be able to view historical statistics about transfers from
        AlgoOptimizer.
        <div class="tooltip">
          <i class="material-icons material-icons-info">info</i>
          <span class="tooltiptext" :class="{ lighttooltiptext: lightMode }">
            If you previously had signed up, rest assured that nothing has
            changed, and you are still participating. Simply enter your wallet
            address below and click 'See my History', and you will be back in
            business
          </span>
        </div>
      </div>
    </div>
    <div class="input-container">
      <input
        type="text"
        class="input"
        :class="{ lightInput: lightMode }"
        placeholder="Wallet address"
        v-model="wallet"
      />
      <div
        class="button"
        :class="{ lightButton: lightMode }"
        v-on:click="processForm"
      >
        View history
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import router from "@/router";
import { computed } from "vue";
import store from "@/store";
import { copyText } from "vue3-clipboard";

export default {
  setup() {
    let showCopied = ref(false);
    const lightMode = computed(() => store.getters.lightMode);
    const copyString =
      "5AFBATSGEMY2ATGVJ465RNW5VKECJYAJACOXXT22O6S2KXB6KN3Q6N6PUM";

    const doCopy = () => {
      copyText(copyString, undefined, (error, event) => {
        if (error) {
          console.log(error);
        } else {
          showCopied.value = true
          setTimeout(() => {
            showCopied.value = false;
          }, 1000);
          console.log(event);
        }
      });
    };

    let copyTextSpaced =
      copyString.substring(0, 30) + " " + copyString.substring(30, copyString.length);

    let wallet = ref(store.getters.walletAddress);

    const processForm = () => {
      console.log(wallet.value);
      store.commit("updateWallet",wallet.value);
      router.push("/statistics/" + wallet.value);
    };

    return {
      showCopied,
      lightMode,
      wallet,
      doCopy,
      copyTextSpaced,
      processForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-statistics;
  color: $body-text;
  text-align: center;
}

.lightMain {
  background-color: $background-statistics-light;
  color: $body-text-light;
}

.line1 {
  width: 300px;
  margin-bottom: 42px;
  @media (min-width: $desktop) {
    width: 400px;
  }
  @media (min-width: $large-desktop) {
    width: 692px;
  }
}

.line2 {
  margin-bottom: 0;
}

.qrcode {
  margin-top: 30px;
  margin-bottom: 30px;
  background: $background-standard 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
  height: 184px;
  width: 187px;
  padding-top: 12px;
  img {
    width: 163px;
  }
}

.lightQrcode {
  background: $body-text-accented-light 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow-light;
}

.address-section {
  height: 125px;
  background: $background-standard 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
  padding: 10px 10px 15px 10px;
  margin-bottom: 49px;
  font-size: 0.875rem;
  .title {
    opacity: 0.68;
    margin-bottom: 9px;
  }
  .address {
    text-align: center;
    width: 290px;
    margin-bottom: 5px;
  }
  .copy {
    color: $highlight;
    cursor: pointer;
  }
  .lightCopy {
    color: $highlight-light;
  }
}

@-moz-document url-prefix() {
  .address-section {
    height: 108px;
  }
}

.lightAddressSection {
  background: $background-standard-light 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow-light;
}

.superscript {
  font-weight: normal;
  font-size: 1rem;
}

h2.heading {
  margin-top: 34px;
}

h2 {
  margin-bottom: 23px;
}

p {
  margin-top: 0;
  width: 300px;
  @media (min-width: $desktop) {
    width: 400px;
  }
  @media (min-width: $large-desktop) {
    width: 720px;
  }
  .link {
    color: $highlight;
  }
  .lightLink {
    color: $highlight-light;
  }
}

p.bold {
  font-weight: bold;
}

.material-icons-info {
  font-size: 1rem;
  margin-left: 4px;
}

.button {
  width: 120px;
  height: 42px;
  background: $highlight 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
  color: $background-standard;
  padding-top: 8px;
  margin-bottom: 15px;
  margin-left: -4px;
  cursor: pointer;
  line-height: 1.7;
  &:hover {
    background: darken($highlight, 10%);
  }
}

.copied {
  margin-top: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.lightButton {
  background: $background-button-light 0% 0% no-repeat padding-box;
  color: $background-standard-light;
  box-shadow: 0px 3px 6px $box-shadow-light;
  &:hover {
    background: darken($background-button-light, 10%);
  }
}

.input-container {
  display: flex;
  align-items: center;
  margin-bottom: 148px;
}

input {
  width: 250px;
  height: 42px;
  display: block;
  margin-top: 10px;
  margin-bottom: 26px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px $box-shadow;
  font-size: 0.875rem;
  padding-left: 20px;
  padding-right: 5px;
  border: none;
  color: $body-text-accented;
  background-color: $background-standard;
  font-family: "Overpass", sans-serif;
  outline: none;
  @media (min-width: $large-desktop) {
    width: 460px;
  }
  &::placeholder {
    color: $body-text-accented;
  }
}

.lightInput {
  box-shadow: 0px 3px 6px $box-shadow-light;
  background: $white;
  color: $body-text-accented-light;
  &::placeholder {
    color: $body-text-accented-light;
  }
}

hr {
  width: 200px;
  border-color: $body-text-accented;
  margin-bottom: 15px;
}

.lightHr {
  border-color: $body-text-accented-light;
  opacity: 0.1;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  font-size: 0.875rem;
  width: 200px;
  background-color: $background-standard;
  color: $body-text;
  text-align: left;
  padding: 5px 0 5px 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -120px;
  padding-right: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  @media (min-width: $large-desktop) {
    width: 300px;
    font-size: 1rem;
  }
}

.lighttooltiptext {
  background-color: $background-standard-light !important;
  color: $body-text-light !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>