<template>
  <div class="main" :class="{ lightMain: lightMode }" v-if="!resetTicker">
    <div class="container">
      <v-text-marquee :speed="80" class="hideLarge">
        <ticker-component></ticker-component>
      </v-text-marquee>
      <ticker-component
        class="hideSmall"
        v-if="!resetTicker"
      ></ticker-component>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { computed, ref, onMounted, onUnmounted } from "vue";
import { VTextMarquee } from "vue-text-marquee";
import TickerComponent from "./TickerComponent.vue";

export default {
  components: { VTextMarquee, TickerComponent },
  setup() {
    let resetTicker = ref(false);
    const eventHandler = () => {
      resetTicker.value = true;
      setTimeout(() => {
        resetTicker.value = false;
      }, 5);
    };
    onMounted(() => {
      window.addEventListener("resize", eventHandler);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", eventHandler);
    });

    const lightMode = computed(() => store.getters.lightMode);

    return { lightMode, resetTicker };
  },
};
</script>

<style lang="scss" scoped>
.main {
  background-color: $background-statistics;
  box-shadow: 0px 3px 6px $box-shadow;
  color: $body-text;
  font-size: 0.875rem;
  padding-top: 4px;
  height: 43px;
  width: 100vw;
  overflow: hidden;
}

.container {
  max-width: $large-desktop;
  margin: auto;
}

.lightMain {
  background-color: $background-statistics-light;
  color: $body-text-light;
  box-shadow: 0px 3px 6px $box-shadow-light;
}

.hideLarge {
  @media (min-width: $large-desktop) {
    display: none;
  }
}

.hideSmall {
  display: none;
  @media (min-width: $large-desktop) {
    display: flex;
  }
}
</style>