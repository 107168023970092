<template>
  <div class="main" :class="{ lightMain: lightMode }">
    <div class="container">
      <h2 class="header">About Algo Optimizer</h2>
      <div class="text">
        <p>
          A little about me. My name is Vincent I am a software developer living
          in New York, NY. I built this software myself with the help of a great
          community that has sprung up in a discord I created as a means to help
          support users of the service. They have inspired several ideas that I
          have implemented, and I am truly grateful for their time and patience
          helping me test and build this service. I intend to run it as long as
          I can afford to do so. If my situation ever changes or I need to move
          on from the site I will do the following. First I will try to find
          someone within the community willing to take it on and drive things
          forward. Finally, if I fail to find someone then I will write one last
          script to automate the returning of the remaining funds to those that
          signed up.
        </p>
        <p>
          I got into Algorand shortly after first reading about it on on
          Coinbase, here was a coin that was fairly stable, and the staking
          rewards were better than any savings account I was ever going to find.
          I have never been an active trader in stocks or coins, my philosophy
          has always been to buy and hold. Algorand felt like a match for my
          personal philosophy. Over the course of about a year I slowly added to
          my holdings and watched the staking rewards come into my account.
          Recently, my interest was piqued again when I found r/algorand and
          started watching posts going by about staking rewards and how to
          optimize them. In particular people were always talking about hand
          calculating their optimal frequency and then going through all kinds
          of hoops to remembers to send their 0Algo transactions. Reading that
          common refrain really made me think there had to be an easier way.
        </p>
        <p>
          To that end, I figured I would build something for myself to start
          with and if it was doable then I would make it available to others.
          Having found some sources on-line for computing the optimal
          compounding I was able to then put together a program that used data
          on the block chain to get information like my wallet balance and my
          last transactions and using that information I could then create the
          0Algo transactions at the interval I calculated. With access to the
          block chain data I could make my calculations adapt as balances
          changed overtime and other transactions occurred eliminating unneeded
          transfers.
        </p>
        <p>
          From this I bought the domain and started building the website.
          Initially, I required people to create an account. Watching people's
          reactions to that requirement and further research into what data was
          available on-line it made me realize this requirement was unnecessary
          and so I reworked things to no longer require a signup. This allowed
          people to maintain their anonymity and to still participate in a
          beneficial service.
        </p>
      </div>
      <hr :class="{ lightHr: lightMode }" />
      <h2>Contributors</h2>
      <div class="grid">
        <div class="cell" :class="{ lightCell: lightMode }">
          <div class="name">@vt</div>
          <div class="role" :class="{ lightRole: lightMode }">
            Initiator / Back-end
          </div>
          <div class="tip" :class="{ lightTip: lightMode }" v-on:click="clicked('vt')">tip</div>
          <qrcode-vue :value="data.vt" v-if="data.show === 'vt'" />
          <div class="tip" v-if="data.show === 'vt'" v-on:click="doCopy('vt')">Copy Address</div>
          <transition name="fade">
            <div class="copied" v-if="showCopied === 'vt'">Copied!</div>
          </transition>
        </div>
        <!-- <div class="cell" :class="{ lightCell: lightMode }">
          <div class="name">@Tiharo</div>
          <div class="role" :class="{ lightRole: lightMode }">Front-end</div>
          <div class="tip" :class="{ lightTip: lightMode }">tip</div>
          <qrcode-vue :value="data.tiharo" />
        </div> -->
        <div class="cell" :class="{ lightCell: lightMode }">
          <div class="name">@Nahradnik</div>
          <div class="role" :class="{ lightRole: lightMode }">
            UI / UX / Design
          </div>
          <div class="tip" :class="{ lightTip: lightMode }" v-on:click="clicked('nt')">tip</div>
          <qrcode-vue :value="data.nt" v-if="data.show === 'nt'" />
          <div class="tip" v-if="data.show === 'nt'" v-on:click="doCopy('nt')">Copy Address</div>
          <transition name="fade">
            <div class="copied" v-if="showCopied === 'nt'">Copied!</div>
          </transition>
        </div>
        <div class="cell" :class="{ lightCell: lightMode }">
          <div class="name">@Tishka</div>
          <div class="role" :class="{ lightRole: lightMode }">
            The Math!
          </div>
          <div class="tip" :class="{ lightTip: lightMode }" v-on:click="clicked('tiska')">tip</div>
          <qrcode-vue :value="data.tiska" v-if="data.show === 'tiska'" />
          <div class="tip" v-if="data.show === 'tiska'" v-on:click="doCopy('tiska')">Copy Address</div>
          <transition name="fade">
            <div class="copied" v-if="showCopied === 'tiska'">Copied!</div>
          </transition>
        </div>
        <div class="cell" :class="{ lightCell: lightMode }">
          <div class="name">@NerdyDudeStuff</div>
          <div class="role" :class="{ lightRole: lightMode }">
            Promotion
          </div>
          <div class="tip" :class="{ lightTip: lightMode }" v-on:click="clicked('nerdy')">tip</div>
          <qrcode-vue :value="data.nerdy" v-if="data.show === 'nerdy'" />
          <div class="tip" v-if="data.show === 'nerdy'" v-on:click="doCopy('nerdy')">Copy Address</div>
          <transition name="fade">
            <div class="copied" v-if="showCopied === 'nerdy'">Copied!</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { computed, reactive, ref } from "vue";
import store from "@/store";
import QrcodeVue from 'qrcode.vue';
import { copyText } from "vue3-clipboard";

export default {
  setup() {
    let showCopied = ref("");
    const lightMode = computed(() => store.getters.lightMode);
    const data = reactive({
      vt:"Z5FZQEQUUN2HEBDG7QXXCD6OLQQGNJTQY27NN2NGOYAXQ4QGS36JVFMP5Q",
      nt:"HV272PGJRXATKKNHRSS7YZOQP6G3EAJZ6C5Q7RL6EDEESVX54RXALSJHKU",
      nerdy:"OGSYLCVIDJKJFS3EUP3WTZTUNLL7YHT4K7RLBUMXRLDJ6HMS4GYTFTTVIQ",
      tiska:"Z4YG56Q7N2CDDTTGE2SJJBVSCXLUZNXKL2GK22KOHGBUQQB6VSJ2J5CYZE",
      tiharo:"unknown",
      show: "",
    })

    const doCopy = (name) => {
      copyText(data[name], undefined, (error, event) => {
        if (error) {
          console.log(error);
        } else {
          showCopied.value = name
          setTimeout(() => {
            showCopied.value = "";
          }, 1000);
          console.log(event);
        }
      });
    };

    const clicked = (str) => {
      if (data.show === str) {
        data.show = "";
      } else {
        data.show = str;
      }
    };

    return { lightMode, data, clicked, showCopied, doCopy, };
  },
  components: {
      QrcodeVue,
  }
};
</script>

<style lang="scss" scoped>

.copied {
  margin-top: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.main {
  display: flex;
  background-color: $background-statistics;
  color: $body-text;
}

.container {
  display: flex;
  flex-direction: column;
  width: $mobile;
  align-items: center;
  margin: auto;
  @media (min-width: $desktop) {
    width: $desktop;
  }
  @media (min-width: $large-desktop) {
    width: $large-desktop;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.lightMain {
  background-color: $background-statistics-light;
  color: $body-text-light;
}

.text p {
  width: 300px;
  font-size: 0.875rem;
  padding-right: 10px;
  @media (min-width: $desktop) {
    width: 500px;
    padding-right: 0;
  }
  @media (min-width: $large-desktop) {
    font-size: 1rem;
    width: 726px;
  }
}

h2 {
  font-size: 1.625rem;
  font-weight: 600;
  @media (min-width: $large-desktop) {
    font-size: 1.875rem;
  }
}

h2.header {
  margin-top: 64px;
}

hr {
  width: 200px;
  border-color: $body-text-accented;
}

.lightHr {
  border-color: $body-text-accented-light;
  opacity: 0.1;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 5px;
  row-gap: 8px;
  margin-bottom: 120px;
  @media (min-width: $large-desktop) {
    grid-template-columns: repeat(4, auto);
    column-gap: 17px;
    row-gap: 20px;
  }
}

.cell {
  width: 149px;
  text-align: center;
  padding-top: 10px;
  background: $background-standard 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
  opacity: 0.56;
  @media (min-width: $large-desktop) {
    width: 179px;
  }
}

.lightCell {
  background: $background-standard-light 0% 0% no-repeat padding-box;
}

.name {
  font-weight: 800;
  margin-bottom: 10px;
}

.role {
  color: $body-text-accented;
  margin-bottom: 13px;
}

.lightRole {
  color: $body-text-accented-light;
}

.tip {
  font-weight: 900;
  color: $highlight;
  cursor: pointer;
}

.lightTip {
  color: $highlight-light;
}

</style>