<template>
  <div class="main" :class="{ lightMain: lightMode }">
    <div class="container">
      <h2 class="header">Privacy Policy</h2>
      <div class="text">
        <p>
          <strong>AlgoOptimizer uses no 3rd party trackers.</strong><br />We store no personal information about our users.  All data used by this site is available on the Algorand block chain.  If you do not run a node, then that data can be see easily here <a href="https://algoexplorer.io" target="_blank">https://algoexplorer.io</a>.  <strong>AlgoOptimzier does not share in any way shape or form the data of its users, and will refuse any solicitation for such data.</strong> AlgoOptimzier will not directly contact users via any mechanism other than the note fields on the 0 Algo transfers. Any such contact attempts are not from this site. Support and user assistance is provided via a public discord chat.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "@/store";
export default {
  setup() {
    const lightMode = computed(() => store.getters.lightMode);
    return { lightMode };
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  background-color: $background-statistics;
  color: $body-text;
}

.container {
  display: flex;
  flex-direction: column;
  width: $mobile;
  align-items: center;
  margin: auto;
  @media (min-width: $desktop) {
    width: $desktop;
  }
  @media (min-width: $large-desktop) {
    width: $large-desktop;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.lightMain {
  background-color: $background-statistics-light;
  color: $body-text-light;
}

.text p {
  width: 300px;
  font-size: 0.875rem;
  padding-right: 10px;
  @media (min-width: $desktop) {
    width: 500px;
    padding-right: 0;
  }
  @media (min-width: $large-desktop) {
    font-size: 1rem;
    width: 726px;
  }
}

h2 {
  font-size: 1.625rem;
  font-weight: 600;
  @media (min-width: $large-desktop) {
    font-size: 1.875rem;
  }
}

h2.header {
  margin-top: 64px;
}

hr {
  width: 200px;
  border-color: $body-text-accented;
}

.lightHr {
  border-color: $body-text-accented-light;
  opacity: 0.1;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 5px;
  row-gap: 8px;
  margin-bottom: 120px;
  @media (min-width: $large-desktop) {
    grid-template-columns: repeat(4, auto);
    column-gap: 17px;
    row-gap: 20px;
  }
}

.cell {
  width: 149px;
  height: 118px;
  text-align: center;
  padding-top: 10px;
  background: $background-standard 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px $box-shadow;
  border-radius: 10px;
  opacity: 0.56;
  @media (min-width: $large-desktop) {
    width: 179px;
  }
}

.lightCell {
  background: $background-standard-light 0% 0% no-repeat padding-box;
}

.name {
  font-weight: 800;
  margin-bottom: 10px;
}

.role {
  color: $body-text-accented;
  margin-bottom: 13px;
}

.lightRole {
  color: $body-text-accented-light;
}

.tip {
  font-weight: 900;
  color: $highlight;
  cursor: pointer;
}

.lightTip {
  color: $highlight-light;
}
</style>