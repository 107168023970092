<template>
  <section class="main">
    <ticker></ticker>
    <calculator-component></calculator-component>
  </section>
</template>

<script>
import Ticker from "../components/about/Ticker.vue";
import CalculatorComponent from "../components/calculator/CalculatorComponent.vue";

export default {
  components: {
    Ticker,
    CalculatorComponent,
  },
};
</script>

<style lang="scss" scoped>
.main {
  grid-area: main;
}
</style>