<template>
  <section class="main">
    <welcome></welcome>
    <getting-started></getting-started>
  </section>
</template>

<script>
import GettingStarted from "../components/homepage/GettingStarted.vue";
import Welcome from "../components/homepage/Welcome.vue";

export default {
  components: { GettingStarted, Welcome },
};
</script>

<style lang="scss" scoped>
.main {
  grid-area: main;
}
</style>
