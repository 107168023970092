<template>
  <header :class="{ lightHeader: lightMode }">
    <div class="container">
      <div class="algo-img">
        <img src="@/assets/icons/Algo.svg" />
      </div>
      <router-link :to="{ name: 'Home' }">
        <div class="title-div">Algo Optimizer</div>
      </router-link>
      <div class="nav">
        <router-link :to="{ name: 'Home' }">
          <div
            class="link"
            :class="{
              'selected-link':
                (selectedRoute == 'Home' || selectedRoute == 'Statistics') &&
                !lightMode,
              'selected-link-light':
                (selectedRoute == 'Home' || selectedRoute == 'Statistics') &&
                lightMode,
            }"
          >
            Optimizer
          </div>
        </router-link>
        <router-link :to="{ name: 'About' }">
          <div
            class="link"
            :class="{
              'selected-link': selectedRoute == 'About' && !lightMode,
              'selected-link-light': selectedRoute == 'About' && lightMode,
            }"
          >
            About
          </div>
        </router-link>
        <router-link :to="{ name: 'FAQ' }">
          <div
            class="link"
            :class="{
              'selected-link': selectedRoute == 'FAQ' && !lightMode,
              'selected-link-light': selectedRoute == 'FAQ' && lightMode,
            }"
          >
            FAQs
          </div>
        </router-link>
        <router-link :to="{ name: 'Calculator' }">
          <div
            class="link"
            :class="{
              'selected-link': selectedRoute == 'Calculator' && !lightMode,
              'selected-link-light': selectedRoute == 'Calculator' && lightMode,
            }"
          >
            Calculator
          </div>
        </router-link>
        <i class="material-icons" @click="modeSwitch" v-if="!lightMode">
          light_mode
        </i>
        <i class="material-icons" @click="modeSwitch" v-if="lightMode">
          dark_mode
        </i>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";

export default {
  setup() {
    const route = useRoute();
    let selectedRoute = ref(route.name);
    if (selectedRoute.value === undefined) {
      selectedRoute.value = "Home";
    }

    store.commit("initialiseStore");
    
    let lightMode = ref(store.getters.lightMode);

    const modeSwitch = () => {
      store.commit("toggleLightMode");
      lightMode.value = store.getters.lightMode;
    };

    watch(
      () => route.path,
      () => {
        selectedRoute.value = route.name;
      }
    );

    return { selectedRoute, modeSwitch, lightMode };
  },
};
</script>

<style lang="scss" scoped>
header {
  grid-area: header;
  position: fixed;
  z-index: 10;
  width: 100%;
  background-color: $black;
  color: $body-text;
  height: 60px;
  padding-left: 10px;
  padding-right: 30px;
  @media (min-width: $desktop) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.lightHeader {
  background-color: $white;
  color: $body-text-light;
}

.container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: $large-desktop;
}

.nav {
  justify-content: space-evenly;
  font-size: 1.1875rem;
  display: flex;
  @media (min-width: $desktop) {
    width: 500px;
  }
}

.title-div {
  white-space: nowrap;
  font-size: 1.625rem;
  font-weight: bold;
  padding-right: 40px;
  @media (min-width: $desktop) {
    padding-right: 0;
  }
}

.material-icons {
  font-size: 1.375rem;
  margin-top: 0.2rem;
  cursor: pointer;
}

@-moz-document url-prefix() {
  .material-icons {
    margin-top: 0;
  }
}

.link {
  border: 1px solid transparent;
  padding: 3px 5px 3px 5px;
  display: none;
  @media (min-width: $desktop) {
    display: unset;
  }
}

.selected-link {
  border: 1px solid $selected-link;
  border-radius: 10px;
  color: $highlight;
  padding: 8px;
  line-height: 1.17;
}

.selected-link-light {
  border: 1px solid $selected-link-light;
  border-radius: 10px;
  color: $highlight-light;
}

.algo-img {
  position: relative;
  top: -2px;
  img {
    width: 100px;
  }
  @media (min-width: $desktop) {
    display: none;
  }
}
</style>
