import { createStore } from "vuex";

export default createStore({
  state: {
    transactions: [],
    lightMode: localStorage.getItem('lightMode'),
    walletAddress: "",
  },
  mutations: {
    updateTransactions(state, payload) {
      state.transactions = payload;
    },
    toggleLightMode(state) {
      state.lightMode = !state.lightMode;
      localStorage.setItem('lightMode', JSON.stringify(state));
    },
    updateWallet(state, payload) {
      state.walletAddress = payload
      localStorage.setItem('lightMode', JSON.stringify(state));
    },
    initialiseStore(state) {
			// Check if the ID exists
			if(localStorage.getItem('lightMode')) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('lightMode')))
				);
			}
		}
  },
  actions: {},
  modules: {},
  getters: {
    transactions: (state) => {
      return state.transactions;
    },
    lightMode: (state) => {
      return state.lightMode;
    },
    walletAddress: (state) => {
      return state.walletAddress;
    }
  }
});
