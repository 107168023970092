<template>
  <div class="main" :class="{ lightMain: lightMode }">
    <div class="container">
      <div class="background-img">
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 1064 1023"
          style="enable-background: new 0 0 800 801.1"
          xml:space="preserve"
        >
          <g>
            <path
              class="path"
              :class="{ lightPath: lightMode }"
              d="M918.5,511.3l137.4-252.9H865.8L832,320.7l-17.2-64.1L747,5.2H592.1L7.8,1017.2h176.9l461-798.3
	l55,205.8l23.2,86.6h-66.8L519.8,764.3h-71.2l-137.4,252.9h190.1l137.4-252.9h71.2l54.8-100.9l95.1,353.8h158.4L883.1,511.3
	L918.5,511.3z"
            />
          </g>
        </svg>
      </div>
      <div class="left">
        <h1 :class="{ lightH1: lightMode }">Get rewards on your rewards!</h1>
        <div class="text">
          <p :class="{ lightP: lightMode }">
            To optimize staking, it is important to compound your balance. If
            you have a Buy and Hold strategy, you need to periodically update
            the balance on the blockchain so that your staking reward is based
            on your current balance.
          </p>
          <p :class="{ lightP: lightMode }">
            To facilitate these updates, a 0 ALGO transaction must be sent to
            your wallet, which will add your staking rewards to your wallet and
            start compounding with that new, higher balance!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "@/store";
export default {
  setup() {
    const lightMode = computed(() => store.getters.lightMode);
    return { lightMode };
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  display: flex;
  background-color: $background-standard;
  overflow: hidden;
  text-align: center;
  margin: auto;
  @media (min-width: $large-desktop) {
    align-items: left;
    text-align: left;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.lightMain {
  background-color: $background-standard-light;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: $mobile;
  max-width: $large-desktop;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $desktop) {
    width: $desktop;
  }
  @media (min-width: $large-desktop) {
    width: $large-desktop;
    flex-direction: row;
    margin: inherit;
  }
}

.text {
  width: 429px;
  margin-bottom: 82px;
}

.background-img {
  display: none;
  position: absolute;
  top: -250px;
  right: -30px;
  z-index: 0;
  height: 100%;
  width: 1000px;
  @media (min-width: $large-desktop) {
    display: block;
  }
}

h1 {
  color: $body-text;
  margin-top: 54px;
  font-size: 1.625rem;
  line-height: 1.17;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $large-desktop) {
    margin-left: inherit;
    margin-right: inherit;
    width: 354px;
    font-size: 3.125rem;
  }
}

.lightH1 {
  color: $body-text-light;
}

p {
  width: 300px;
  padding-right: 10px;
  color: $body-text-accented;
  font-size: 0.875rem;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $desktop) {
    width: 450px;
  }
  @media (min-width: $large-desktop) {
    font-size: 1rem;
    width: 400px;
    margin-left: inherit;
    margin-right: inherit;
  }
}

.lightP {
  color: $body-text-accented-light;
}

.path {
  fill: none;
  stroke: $algo-img-background;
  stroke-miterlimit: 10;
}

.lightPath {
  stroke: $algo-img-background-light;
}

@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}

.path {
  animation: dash 30s linear infinite;
  stroke-dasharray: 4;
}
</style>