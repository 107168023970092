import axios from 'axios';
import VueAxios from 'vue-axios';
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Faq from "../views/FAQ.vue";
import Terms from "../views/Terms.vue"
import Privacy from "../views/Privacy.vue"
import Statistics from "../views/Statistics.vue";
import Calculator from "../views/Calculator.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy
  },
  {
    path: "/statistics/:wallet([2-7A-Z]+)",
    name: "Statistics",
    component: Statistics
  },
  {
    path: "/calculator",
    name: "Calculator",
    component: Calculator
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Home",
    component: Home
  }
];

const router = createRouter({
  scrollBehavior(to) {
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
