<template>
  <section class="main">
    <ticker></ticker>
    <rewards></rewards>
    <terms></terms>
  </section>
</template>

<script>
import Terms from "../components/terms/Terms.vue";
import Rewards from "../components/terms/Rewards.vue";
import Ticker from "../components/about/Ticker.vue";

export default {
  components: { Ticker, Rewards, Terms },
};
</script>

<style lang="scss" scoped>
.main {
  grid-area: main;
}
</style>