<template>
  <footer :class="{ lightFooter: lightMode }">
    <div class="container">
      <div class="copyright">© 2021 Algo Optimizer</div>
      <div class="logo">
        <div class="logo-img">
          <img src="@/assets/images/Logo.svg" />
        </div>
        <div class="logo-text">
          <span class="logo-span">Algo</span>
          <span class="logo-span">Optimizer</span>
        </div>
      </div>
      <div class="sitemap">
        <div class="sitemap-header">pages</div>
        <div class="sitemap-header">community</div>
        <div class="sitemap-header">legal</div>
        <router-link :to="{ name: 'Home' }">
          <div class="sitemap-content">Optimizer</div>
        </router-link>
        <a href="https://discord.gg/Wr2KDKFb7v">
          <div class="sitemap-content">Discord</div>
        </a>
        <router-link :to="{ name: 'Terms' }">
          <div class="sitemap-content">Terms</div>
        </router-link>
        <router-link :to="{ name: 'About' }">
          <div class="sitemap-content">About</div>
        </router-link>
        <a href="https://github.com/algooptimizer/">
          <div class="sitemap-content">Github</div>
        </a>
        <router-link :to="{ name: 'Privacy' }">
          <div class="sitemap-content">Privacy</div>
        </router-link>
        <router-link :to="{ name: 'FAQ' }">
          <div class="sitemap-content">FAQ</div>
        </router-link>
        <a href="https://github.com/algooptimizer/"></a>
        <a href="https://github.com/algooptimizer/"></a>    
        <router-link :to="{ name: 'Calculator' }">
          <div class="sitemap-content">Calculator</div>
        </router-link>
      </div>
    </div>
    <div class="footer-nav">
      <router-link :to="{ name: 'Home' }">
        <div
          class="nav-link"
          :class="{
            'selected-link':
              (selectedRoute == 'Home' || selectedRoute == 'Statistics') &&
              !lightMode,
            'selected-link-light':
              (selectedRoute == 'Home' || selectedRoute == 'Statistics') &&
              lightMode,
          }"
        >
          <i class="material-icons">home</i>
          Optimizer
        </div>
      </router-link>
      <router-link :to="{ name: 'About' }">
        <div
          class="nav-link"
          :class="{
            'selected-link': selectedRoute == 'About' && !lightMode,
            'selected-link-light': selectedRoute == 'About' && lightMode,
          }"
        >
          <i class="material-icons">info</i>
          About
        </div>
      </router-link>
      <router-link :to="{ name: 'FAQ' }">
        <div
          class="nav-link"
          :class="{
            'selected-link': selectedRoute == 'FAQ' && !lightMode,
            'selected-link-light': selectedRoute == 'FAQ' && lightMode,
          }"
        >
          <i class="material-icons">quiz</i>
          FAQs
        </div>
      </router-link>
      
      <router-link :to="{ name: 'Calculator' }">
        <div
          class="nav-link"
          :class="{
            'selected-link': selectedRoute == 'Calculator' && !lightMode,
            'selected-link-light': selectedRoute == 'Calculator' && lightMode,
          }"
        >
          <i class="material-icons">quiz</i>
          Calculator
        </div>
      </router-link>
    </div>
  </footer>
</template>

<script>
import { computed, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    let selectedRoute = ref(route.name);
    if (selectedRoute.value === undefined) {
      selectedRoute.value = "Home";
    }

    watch(
      () => route.path,
      () => {
        selectedRoute.value = route.name;
      }
    );

    const lightMode = computed(() => store.getters.lightMode);

    return { lightMode, selectedRoute };
  },
};
</script>

<style lang="scss" scoped>
footer {
  grid-area: footer;
  position: fixed;
  z-index: 10;
  bottom: 0;
  background-color: $black;
  color: $body-text-accented;
  width: 100%;
  height: 60px;
  padding-top: 53px;
  padding-left: 60px;
  padding-right: 60px;
  @media (min-width: $desktop) {
    height: 290px;
    position: inherit;
  }
  @media (min-width: $large-desktop) {
    height: 389px;
  }
}

.lightFooter {
  background-color: $body-text-light;
  color: $white;
}

.container {
  display: none;
  justify-content: space-between;
  width: 100%;
  max-width: $large-desktop;
  margin: auto;
  @media (min-width: $desktop) {
    display: flex;
  }
  @media (min-width: $large-desktop) {
    position: relative;
  }
}

.logo {
  display: none;
  @media (min-width: $large-desktop) {
    display: flex;
  }
  img {
    width: 82px;
  }
}

.logo-span {
  display: block;
  font-size: 1.625rem;
  font-weight: bold;
  height: 28px;
  margin-left: 10px;
}

.logo-text {
  margin-top: 8px;
}

.sitemap {
  display: grid;
  grid-template-columns: repeat(3, auto);
  row-gap: 18px;
  margin-right: 200px;
  padding-left: 30px;
  margin: auto;
  @media (min-width: $desktop) {
    column-gap: 90px;
  }
  @media (min-width: $large-desktop) {
    column-gap: 234px;
  }
}

.sitemap-header {
  text-transform: uppercase;
  font-size: 1.1875rem;
  font-weight: 800;
}

.sitemap-content {
  cursor: pointer;
}

.copyright {
  display: none;
  @media (min-width: $large-desktop) {
    display: block;
    position: absolute;
    top: 250px;
    left: 0;
  }
}

.footer-nav {
  display: flex;
  margin-top: -45px;
  width: 100%;
  justify-content: space-evenly;
  font-size: 0.6875rem;
  @media (min-width: $desktop) {
    display: none;
  }
}

.nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.material-icons {
  margin-bottom: 2px;
}

.selected-link {
  color: $highlight;
}

.selected-link-light {
  color: $highlight-light;
}
</style>
