<template>
  <section class="main">
    <ticker></ticker>
    <faq-component></faq-component>
  </section>
</template>

<script>
import Ticker from "../components/about/Ticker.vue";
import FaqComponent from "../components/faq/FaqComponent.vue";

export default {
  components: { Ticker, FaqComponent },
};
</script>

<style lang="scss" scoped>
.main {
  grid-area: main;
}
</style>