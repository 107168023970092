<template>
  <div class="main" :class="{ lightMain: lightMode }">
    <h2 :class="{ lightH2: lightMode }">Transfer History</h2>
    <div class="grid">
      <div class="header header-left">ID</div>
      <div class="header header-left">Date</div>
      <div class="header">Reward</div>
      <div class="header header-right hide-small">Transaction ID</div>
      <div class="header header-right hide-large">Tx ID</div>
      <template v-for="(data, index) in rowData" :key="index">
        <div class="column" :class="{ lightColumn: lightMode }">
          {{ data.id }}
        </div>
        <div class="column" :class="{ lightColumn: lightMode }">
          {{ data.created_at }}
        </div>
        <div class="column algo-column" :class="{ lightColumn: lightMode }">
          <div>
            <img src="@/assets/icons/Algo.svg" />
          </div>
          <div class="reward">
            {{ data.reward }}
          </div>
        </div>
        <div
          class="column transaction-column"
          :class="{ lightTransactionColumn: lightMode }"
        >
          <span v-html="data.transactionid"></span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { computed } from "@vue/runtime-core";
import { dateToStr } from "@/helpers";

export default {
  setup() {
    let lightMode = computed(() => store.getters.lightMode);

    const rowData = computed(() =>
      store.getters.transactions.map((item) => {
        return {
          ...item,
          reward: item.reward/1000000.0,
          transactionid: "<a href=\"https://algoexplorer.io/tx/" + item.transactionid + "\" target=\"_blank\">" + item.transactionid + "</a>",
          created_at: dateToStr(item.created_at),
        };
      })
    );

    return {
      lightMode,
      rowData,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-statistics;
}

.lightMain {
  background-color: $background-statistics-light;
}

.grid-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}

h2 {
  font-size: 1.875rem;
  color: $body-text;
}

.lightH2 {
  color: $body-text-light;
}

.grid {
  overflow: hidden;
  display: grid;
  border-radius: 10px 10px 0px 0px;
  font-size: 0.875rem;
  color: $body-text;
  margin-bottom: 150px;
  background-color: $selected;
  grid-template-columns: 0px 75px 100px 80px;
  width: 255px;
  @media (min-width: $desktop) {
    grid-template-columns: 0px 120px 100px 390px;
    width: 610px;
    margin-bottom: 60px;
  }
  @media (min-width: $large-desktop) {
    grid-template-columns: 0px 180px 100px 490px;
    width: 770px;
  }
}

.header {
  background: $background-standard;
  height: 38px;
  padding-top: 8px;
  padding-left: 10px;
}

.header-left {
  border-radius: 10px 0 0 0;
}

.header-right {
  border-radius: 0 10px 0 0;
}

.column {
  overflow: hidden;
  white-space: nowrap;
  height: 38px;
  padding-top: 8px;
  padding-left: 10px;
  border-bottom: 1px solid $background-standard;
}

.lightColumn {
  color: $black;
}

.transaction-column {
  color: $highlight;
}

.lightTransactionColumn {
  color: $highlight-light;
}

.algo-column {
  display: flex;
  align-items: center;
  line-height: 1.5;
  img {
    position: relative;
    top: -1px;
    width: 30px;
    margin-right: -5px;
  }
}

.reward {
  padding-bottom: 5px;
}

.hide-small {
  display: none;
  @media (min-width: $desktop) {
    display: inherit;
  }
}

.hide-large {
  @media (min-width: $desktop) {
    display: none;
  }
}
</style>
