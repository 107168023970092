<template>
  <div class="main" :class="{ lightMain: lightMode }">
    <h1 :class="{ lightH1: lightMode }">FAQs</h1>
    <div class="faqs">
      <div
        class="faq-container"
        @click="clicked(index)"
        :class="{
          selected: checkRow(index) !== -1 && !lightMode,
          lightSelected: checkRow(index !== -1) && lightMode,
          lightFaqContainer: lightMode,
        }"
        v-for="(data, index) in data"
        :key="index"
      >
        <div class="question" :class="{ lightQuestion: lightMode }">
          <div
            :class="{
              questionHighlight: checkRow(index) !== -1 && !lightMode,
              lightQuestionHighlight: checkRow(index) !== -1 && lightMode,
              opaqueQuestion: checkRow(index) === -1,
            }"
          >
            {{ data.question }}
          </div>
          <div v-if="checkRow(index) == -1">+</div>
          <div v-else class="highlight" :class="{ lightHighlight: lightMode }">
            -
          </div>
        </div>
        <div
          class="answer"
          :class="{ lightAnswer: lightMode }"
          v-if="checkRow(index) !== -1"
        >
          {{ data.answer }}
          <div class="lastupdated" :class="{ lightLastUpdated: lightMode }">
            {{ data.lastUpdated }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { computed } from "vue";
import store from "@/store";

export default {
  setup() {
    const lightMode = computed(() => store.getters.lightMode);
    const data = [
      {
        question: `How does the site work?`,
        answer: `Using your 1 Algo deposit the service automatically does 0 Algo
          deposits on the interval that is calculated to be optimal for your
          Balance.`,
        lastUpdated: `Last Updated: 2021-04-05 04:23:44`,
      },
      {
        question: `How do I pause and resume the server?`,
        answer: `By sending a 0 Algo transaction to our wallet with a note of pause you
          can indefinitely suspend the service. Sending a 0 Algo transaction to
          our wallet with a note of resume will start the service back up.`,
        lastUpdated: `Last Updated: 2021-04-05 04:24:40`,
      },
      {
        question: `Does my balance update over time and how often?`,
        answer: `By watching the block chain for transactions we are able to keep your
          balance always up to date. So your balance should update on the
          optimizer within a few seconds of the transactions appearing on the
          blockchain. When these updates happen a new compound period is
          calculated. Also those transactions on the blockchain will reset the
          timer for the next 0 Algo transaction since a 0 Algo transaction in
          between would result in few rewards.`,
        lastUpdated: `Last Updated: 2021-04-05 04:25:14`,
      },
      {
        question: `Why only 900 transactions from a 1 Algo deposit?`,
        answer: `I take a 10% cut of each deposit in order to fund server upkeep and
          further development of the system.`,
        lastUpdated: `Last Updated: 2021-04-05 04:26:40`,
      },
      {
        question: `How do I get support?`,
        answer: `Join my discord I am always lurking there as @vt`,
        lastUpdated: `Last Updated: 2021-04-05 04:27:01`,
      },
    ];
    let selected = ref([]);

    const checkRow = (row) => {
      return selected.value.findIndex((e) => e === row);
    };

    const clicked = (row) => {
      const val = checkRow(row);
      if (val === -1) {
        selected.value.push(row);
      } else {
        selected.value.splice(val, 1);
      }
    };

    return { lightMode, selected, clicked, checkRow, data };
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-standard;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin: auto;
  @media (min-width: $desktop) {
    min-height: 100%;
  }
}

.lightMain {
  background-color: $background-standard-light;
}

.faq-container {
  border-bottom: 1px solid $body-text-accented;
  padding: 20px 15px 20px 15px;
  cursor: pointer;
}

.lightFaqContainer {
  border-bottom: 1px solid $body-text-accented-light;
}

.faq-container:first-of-type {
  border-top: 1px solid $body-text-accented;
}

.lightFaqContainer:first-of-type {
  border-top: 1px solid $body-text-accented-light;
}

.faqs {
  width: 325px;
  margin-bottom: 135px;
  @media (min-width: $desktop) {
    width: 550px;
  }
  @media (min-width: $large-desktop) {
    width: 700px;
  }
}

.question {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  color: $body-text;
  font-weight: 600;
  @media (min-width: $desktop) {
    font-size: 1.25rem;
  }
}

.lightQuestion {
  color: $body-text-light;
}

.answer {
  color: $body-text;
  margin-bottom: 17px;
  margin-top: 23px;
  font-size: 0.875rem;
  @media (min-width: $desktop) {
    font-size: 1.25rem;
  }
}

.lightAnswer {
  color: $body-text-light;
}

.lastupdated {
  color: $highlight;
  font-size: 0.75rem;
  margin-top: 17px;
}

.lightLastUpdated {
  color: $highlight-light;
}

.highlight {
  color: $highlight;
  font-size: 1.5rem;
}

.lightHighlight {
  color: $highlight-light;
}

.questionHighlight {
  opacity: 1;
  color: $highlight;
}

.lightQuestionHighlight {
  opacity: 1;
  color: $highlight-light;
}

.opaqueQuestion {
  opacity: 0.5;
}

.selected {
  background-color: $selected;
}

.lightSelected {
  background-color: $selected-light;
}

h1 {
  font-weight: 600;
  font-size: 3.125rem;
  color: $body-text;
}

.lightH1 {
  color: $body-text-light;
}
</style>