<template>
  <section class="main">
    <ticker></ticker>
    <rewards></rewards>
    <history></history>
  </section>
</template>

<script>
import History from "../components/about/History.vue";
import Rewards from "../components/about/Rewards.vue";
import Ticker from "../components/about/Ticker.vue";

export default {
  components: { Ticker, Rewards, History },
};
</script>

<style lang="scss" scoped>
.main {
  grid-area: main;
}
</style>