<template>
  <div class="container">
    <div class="ticker-container">
      <div class="ticker">Active users:</div>
      <div class="ticker-value" :class="{ lightTickerValue: lightMode }">
        {{ stats.data.totalUsers }}
      </div>
    </div>
    <div class="ticker-container">
      <div class="ticker">New users:</div>
      <div class="ticker-value" :class="{ lightTickerValue: lightMode }">
        {{ stats.data.lastUsers }}
      </div>
    </div>
    <div class="ticker-container">
      <div class="ticker">Last transactions:</div>
      <div class="ticker-value" :class="{ lightTickerValue: lightMode }">
        {{ stats.data.lastTransactions }}
      </div>
    </div>
    <div class="ticker-container push-down">
      <div class="ticker">Last rewards:</div>
      <div class="ticker-value" :class="{ lightTickerValue: lightMode }">
        <div class="algo-container">
          <div class="algo-symbol">
            <img src="@/assets/icons/Algo.svg" />
          </div>
          <div class="algo-value">{{ stats.data.lastRewards }}</div>
        </div>
      </div>
    </div>
    <div class="ticker-container">
      <div class="ticker">Avg compounding time:</div>
      <div class="ticker-value" :class="{ lightTickerValue: lightMode }">
        {{ stats.secondsToHms(stats.data.averageCompoundingTime) }}
      </div>
    </div>
    <div class="ticker-container push-down">
      <div class="ticker">Total rewards:</div>
      <div class="ticker-value" :class="{ lightTickerValue: lightMode }">
        <div class="algo-container">
          <div class="algo-symbol">
            <img src="@/assets/icons/Algo.svg" />
          </div>
          <div class="algo-value">{{ stats.data.totalStakingRewards }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStats } from "@/helpers";
import { computed } from "vue";
import store from "@/store";

export default {
  setup() {
    const lightMode = computed(() => store.getters.lightMode);

    const stats = getStats();
    return { lightMode, stats };
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
}

.ticker-container {
  display: flex;
}

.ticker-value {
  margin-left: 4px;
  margin-right: 30px;
  color: $highlight;
}

.lightTickerValue {
  color: $highlight-light;
}

.algo-container {
  display: flex;
  align-items: flex-start;
}

.algo-symbol {
  position: relative;
  top: -3px;
  margin-right: -5px;
  margin-top: -1.5px;
  img {
    width: 25px;
  }
}

@-moz-document url-prefix() {
  .algo-symbol img {
    margin-top: -3.5px;
  }
  .container {
    padding-top: 3px;
  }
}

.push-down {
  margin-top: 9px;
}
</style>