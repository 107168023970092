<template>
  <section class="main">
    <ticker></ticker>
    <statistics-component></statistics-component>
    <tracking-history></tracking-history>
  </section>
</template>

<script>
import TrackingHistory from "../components/statistics/TrackingHistory.vue";
import Ticker from "../components/about/Ticker.vue";
import StatisticsComponent from "../components/statistics/StatisticsComponent.vue";

export default {
  components: {
    Ticker,
    StatisticsComponent,
    TrackingHistory,
  },
};
</script>

<style lang="scss" scoped>
.main {
  grid-area: main;
}
</style>