import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import store from './store'
import router from './router'
import VueClipboard from 'vue3-clipboard'

createApp(App).use(router).use(store).use(VueAxios, axios).use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  }).mount('#app')