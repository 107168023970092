import { onMounted, onUnmounted, reactive } from "vue";
import axios from "axios";

export function dateToStr(date) {
  var d = new Date(Date.parse(date));
  return d.toLocaleDateString() + " " + d.toLocaleTimeString();
}

export function secondsToHms(value) {
  if (value == 0) {
    return "####";
  }
  var d = Number(value * 60);

  var days = Math.floor(d / (24 * 3600));
  d = d % (24 * 3600);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var dDislay = days > 0 ? `${days}d ` : "";
  var hDisplay = h > 0 ? `${h}h ` : "";
  var mDisplay = m > 0 ? `${m}m ` : "";
  var sDisplay = s > 0 ? `${s}s ` : "";
  return dDislay + hDisplay + mDisplay + sDisplay;
}

export function toFixed2(value, precision) {
  var precision = precision || 0,
      power = Math.pow(10, precision),
      absValue = Math.abs(Math.round(value * power)),
      result = (value < 0 ? '-' : '') + String(Math.floor(absValue / power));

  if (precision > 0) {
      var fraction = String(absValue % power),
          padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');
      result += '.' + padding + fraction;
  }
  return result;
}

export function trueSecondsToHms(value) {
  if (value == 0) {
    return "####";
  }
  var d = value

  var days = Math.floor(d / (24 * 3600));
  d = d % (24 * 3600);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var dDislay = days > 0 ? `${days}d ` : "";
  var hDisplay = h > 0 ? `${h}h ` : "";
  var mDisplay = m > 0 ? `${m}m ` : "";
  var sDisplay = s > 0 ? `${s}s ` : "";
  return dDislay + hDisplay + mDisplay + sDisplay;
}

export function getStats() {
  const data = reactive({
    totalStakingRewards: "####",
    averageCompoundingTime: 0,
    lastRewards: "####",
    lastTransactions: "####",
    lastUsers: "####",
    totalUsers: "####"
  });

  // how do I move this to an external file?
  const doRequest = async () => {
    const response = await axios.get(
      "https://algooptimizer.com/service/sitestats"
    );
    return await response.data;
  };

  const getter = async () => {
    const response = await doRequest();

    data.totalUsers = response.users;
    data.totalStakingRewards = response.total_reward;
    data.averageCompoundingTime = response.average_compound;
    data.lastRewards = response.last_24_rewards;
    data.lastTransactions = response.transaction_count;
    data.lastUsers = response.accounts_count;
  };

  let token;
  onMounted(() => {
    getter();
    token = window.setInterval(getter, 5000);
  });

  onUnmounted(() => {
    window.clearInterval(token);
  });

  return { data, secondsToHms };
}
